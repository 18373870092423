<template>
    <v-container>
       <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
       <AConfirmation :isShow="isPendingSwapCompany" @cancel="cancelSubmit" @confirm="updateCurrentCompany" />
       <ASuccessFour :api="this.api" />
       <div class="d-flex justify-start">
          <v-btn @click="() => {
             this.$router.go(-1);
          }" class="mb-3" color="primary">
             <v-icon>
                mdi-arrow-left-bold
             </v-icon>
          </v-btn>
       </div>
       <v-skeleton-loader v-if="api.isLoading" ref="skeleton" type="table">
 
       </v-skeleton-loader>
       <v-card v-else>
          <v-toolbar class="white--text text-h5 mb-4" color="secondary">
            Edit Staff
          </v-toolbar>
          <div>
             <v-card class="ma-5 pa-2">
                <v-row no-gutters>
                   <v-col>
                         <div class="px-2">
                        <div
                           class="d-flex flex-row">
                           <div class="flex mr-2">
                              <v-text-field v-model="user.user_fname" label="First Name" dense outlined>
                              </v-text-field>
   
                           </div>
                           <div
                              class="flex">
                              <v-text-field v-model="user.user_lname" label="Last Name" dense outlined>
   
                              </v-text-field>
                           </div>
                        </div>
                         <div
                           class="d-flex flex-row">
                           <div class="flex mr-2">
                              <v-text-field v-model="user.email" label="Email" dense outlined>
   
                              </v-text-field>
                           </div>
                           <div class="flex">
                              <v-text-field v-model="user.mobile" label="Mobile Phone" dense outlined>
   
                              </v-text-field>
                           </div>

                         </div>
                         
                         <div class="d-flex ">
                           <v-autocomplete label="Masterclass Role" :items="RoleOptions" v-model="user.user_role" dense outlined>

                           </v-autocomplete>
                        </div>
                        <div class="d-flex ">
                              <v-autocomplete label="AAE Role" :items="AAERoleOptions"
                              item-text="text" item-value="value" v-model="aaeRole" dense outlined>
                              </v-autocomplete>
                        </div>
                        <div
                           class="d-flex ">
                           <v-autocomplete
                              label="Vision Role"
                              :items="VisionRoleOptions"
                              item-text="text"
                              item-value="value"
                              v-model="visionRole"
                              dense
                              outlined>
                           </v-autocomplete>
                        </div>   
                        <div
                            class="d-flex flex-row">
                            <div class="flex mr-2">
                               <v-autocomplete label="Main Category" :items="MainCategoryOptions"
                                  v-model="user.subscription_type" dense outlined>
       
                               </v-autocomplete>
                            </div>
                            <div class="flex">
                               <v-autocomplete
                               :items="InternalStaffOptions" v-model="user.branch" label="Branch" dense outlined>
                               </v-autocomplete>
                            </div>
                         </div>
                        <!-- <div v-if="checkIfCanAccessBonusOffer()" class="d-flex px-2">
                              <v-autocomplete dense outlined v-model="user.user_account_type" :items="AccountUserOptions" label="User Account Type">
                              </v-autocomplete>
                        </div>
                        <div v-if="checkIfCanAccessBonusOffer()" class="d-flex px-2">
                           <v-text-field dense outlined v-model="user.user_bonus_offer" label="User Bonus Offer">
                           </v-text-field>
                        </div> -->
                      </div>
                   </v-col>
               </v-row>
                  
             </v-card>
          </div>
          <div class="d-flex justify-end pa-5">
 
             <v-btn color="primary" plain @click="() => {
                this.$router.go(-1);
             }">
                Cancel
             </v-btn>
             <v-btn color="primary" @click="() => {
                this.isPending = true;
             }">
                Confirm
             </v-btn>
          </div>
       </v-card>
    </v-container>
 </template>
 
 <script>
 import AConfirmation from '../../components/common/AConfirmation.vue';
 import ASuccessFour from '../../components/common/ASuccessFour.vue';
//  import ComponentDateModal from '../../components/date/ComponentDateModal.vue';
 
 export default{
     data: () => ({
         isPendingSwapCompany:false,
         isPending: false,
         companies: null,
         companiesAvailable:null,
         companiesAvailableSelected:null,
         tempCompaniesAvailableSelected:null,
         users: null,
         userReferralOptions:null,
         isVerified: false,
         isVerifiedCustomValue: 'Verified',
         isNotVerifiedCustomValue: 'Not Verified',
         isEnable: false,
         isEnableCustomValue: 'Enable',
         isNotEnableCustomValue: 'Disable',
         csPICOptions:null,
         aaeRole:"disabled",
         visionRole:"disabled",
         AccountUserOptions:['primary',"secondary"],
         BonusOfferOptions: ['None'],
         MainCategoryOptions : ["SmartCFO Client","TaxPOD Client","Internal Staff"],
         RoleOptions:[
            {
                text:'User',
                value:'User',
            },
            {
                text:'Admin',
                value:'admin'
            },
            {
                text:'Instructor',
                value:'instructor'
            },
         ],
         AAERoleOptions:[
            {
               text:'No access to AAE',
               value:'disabled'
            },
            {
                text:'Client',
                value:'client'
            },
            {
                text:'Consultant',
                value:'consultant',
            },
            {
                text:'Tax Expert',
                value:'tax_expert',
            },
            {
                text:'Admin',
                value:'admin'
            },
            {
                text:'Manager',
                value:'manager'
            }
         ],
         VisionRoleOptions:[
            {
               text:'No access to vision',
               value:'disabled'
            },
            {
               text:'Salesperson',
               value:'salesperson'
            },
            {
               text:'Customer service',
               value:'customer_service'
            },
            {
               text:'Operation',
               value:'operation'
            },
            {
               text:'Admin',
               value:'vision_admin',
            },
            {
               text:'Sales team lead',
               value:'sales_team_lead',
            },
            {
               text:'Sales manager',
               value:'sales_manager'
            },
         ],
         ExistingClientOptions:['GST','YYC GST','Yes','998','HK PHUAH'],
         ActiveOptions:['Yes','No','Webinar'],
         InternalStaffOptions:["HQ & COE","CM","Penang","Johor","Klang","Boss Boleh"],
         api: {
             isLoading: false,
             isError: false,
             error: null,
             url: null,
             isSuccesful: false,
             success: null,
         },
         user:{
            user_fname: null,
            user_lname:null,
            email:null,
            mobile:null,
            subcription_type:null,
            branch:null,
            existing_client:null,
            user_active:null,
            email_verified_at:null,
            status:null,
         },
     }),
     computed: {
         ComputeCompanyIsVerified(verified_at) {
             return (verified_at != null) ? true : false;
         },
         MappingSwitchIsVerified: {
             get(tempIsVerified) {
                 return tempIsVerified == true ? this.isVerifiedCustomValue : this.isNotVerifiedCustomValue;
             },
             set(value) {
                 this.isVerified = value === this.isVerifiedCustomValue;
             }
         },
         MappingSwitchIsEnable: {
             get() {
                 return this.isEnable ? this.isEnableCustomValue : this.isNotEnableCustomValue;
             },
             set(value) {
                 this.isEnable = value === this.isEnableCustomValue;
             },
         }
     },
     created() {
         this.api.callbackReset = () => {
             this.api.isLoading = true;
             this.api.isError = false;
         };
         this.api.callbackError = (e) => {
             this.api.isLoading = false;
             this.api.isError = true;
             this.api.error = e;
         };
         this.api.callbackSuccess = (resp) => {
             if (resp.class === "getUser") {
                 this.user = resp.data;
                 this.isVerified = this.user.email_verified_at !=null? true:false;
                 this.isEnable = this.user.user_status!=0?true:false
                
              
             }
             if(resp.class === 'getUserRole') {
                  let tempAAERoleOptions = this.AAERoleOptions.map(item=>item.value);
                  let tempVisionRoleOptions = this.VisionRoleOptions.map(item=>item.value);
            
                resp.data.forEach(element => {
                  if(tempAAERoleOptions.includes(element.role)) {
                     this.aaeRole = element.role;
                  }
                  if(tempVisionRoleOptions.includes(element.role)) {
                     this.visionRole = element.role;
                  }
               });
              
             }
             if(resp.class==="getCompaniesClientActive"){
                 let tempCompanies = resp.data.companies.filter(function(item){
                     if(item.verification!='Expired'){
                         return true;
                     }
                 })
                 this.companiesAvailable = tempCompanies;
             }
             if(resp.class=="updateUser"){
                 this.api.isSuccesful = true;
                 this.api.success = "Succesfully update user detail";
             }
             if(resp.class=="getAvailableReferralUser") {
                 this.userReferralOptions = resp.data;
             }
             this.api.isLoading = false;
             this.api.isError = false;
         };
     },
     mounted() {
         this.fetch();
     },
     methods: {
         fetch() {
            //  let fetchUserAvailableReferralApi = this.fetchUserAvailableReferral();
             this.$axios.all([
                 this.$api.fetch(this.fetchUser()),
                 this.$api.fetch(this.fetchRole()),
               //   this.$api.fetch(fetchUserAvailableReferralApi),
             ]);
 
          if (this.$store.getters.getRole.includes('developer')) {
             let fetchCompaniesApi = this.fetchCompanies();
             this.$api.fetch(fetchCompaniesApi);
          }
 
         },
         fetchRole() {
            let tempApi = this.$_.clone(this.api);
            tempApi.url = process.env.VUE_APP_SERVER_API.replace("v1","v2")+"/users/role/"+this.$router.history.current.params.id;
            tempApi.method = "GET";
            return tempApi;
         },
         fetchUserAvailableReferral(){
             let tempApi = this.$_.clone(this.api);
             tempApi.method ="GET";
             tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/user/available/"+this.$router.history.current.params.id;
             return tempApi;
         },
         
        //  fet
         fetchUser() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API?.replace("v1","v2")+"/users/"+this.$router.history.current.params.id;
            return tempApi;
         },
         validateInput() {
             this.isPending = true;
         },
      
         submit(){
             this.isPending = false;
             let tempApi = this.$_.clone(this.api);
             tempApi.method = "PUT";
             tempApi.url = process.env.VUE_APP_SERVER_API.replace("v1","v2")+"/users/"+this.$router.history.current.params.id;
            tempApi.params = {
               'fname': this.user.user_fname,
               'lname': this.user.user_lname,
               'email': this.user.email,
               'mobile':this.user.mobile,
               'subcription_type':this.user.subscription_type,
               'branch':this.user.branch,
               'aae_role':this.aaeRole,
               'vision_role':this.visionRole,
               'existing_client':this.user.existing_client,
               'role':this.user.user_role,
               'active':this.user.user_active,
               'email_verified_at':this.isVerified,
               'status':this.isEnable,
            };
 
          this.$api.fetch(tempApi);
 
       },
       checkIfCanAccessOnboarding() {
          if (this.$store.getters.getFeatures.onboarding.status == 1 || (this.$store.getters.getFeatures.onboarding.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
             return true;
          }
          return false;
       },
       checkIfCanAccessBonusOffer() {
          if (this.$store.getters.getFeatures.accounttype.status == 1 || (this.$store.getters.getFeatures.accounttype.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
             return true;
          }
          return false;
       },
       cancelSubmit() {
          this.isPending = false;
          this.isPendingSwapCompany = false;
       },
       updateCurrentCompany() {
          let tempApi = this.$_.clone(this.api);
          tempApi.method = "PUT";
          tempApi.url = process.env.VUE_APP_SERVER_API + ""
       }
    },
    components: { AConfirmation, ASuccessFour }
 }
 </script>